
import { defineComponent, computed } from 'vue'
import { store } from '@/store';

export default defineComponent({
    setup() {
        const websiteUrl = computed(() => {
            return process.env.VUE_APP_PAYMENT_GATEWAY_URL
        })

        const host = computed(() => {
            return (process.env.VUE_APP_FOLDER !== '') ? `/${process.env.VUE_APP_FOLDER}/personal-details` : `/personal-details`;
        });

        const language = computed(() => {
            return window.localStorage.getItem('language');
        })

        return {
            host,
            language,
            websiteUrl
        }
    },
})
