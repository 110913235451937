
  import { defineComponent } from 'vue'
  
  export default defineComponent({
      props: {
        fullscreenLoading: {
          type: Boolean,
          required: true,
          default: false
        },
        isCenter: {
          type: Boolean,
          required: false,
          default: false
        }
      }
  })
  