import { Generics } from '@/core/types';


export type State = {
  quotes: Array<Generics>;
  informations: Array<Generics>;
  interested_policy:any;
  whatapp_template:string | null;
  benefitsArray:Array<any>;
  vat:number;
  quote_benefit_id: number | null;
  compare_list:Array<any>;
  hasError: boolean;
  lead: Array<Generics>;
}

export const state: State = {
  quotes:  [] as Array<Generics>,
  informations: [] as Array<Generics>,
  interested_policy:null,
  whatapp_template:null,
  vat:0,
  quote_benefit_id: 0,
  benefitsArray: [],
  compare_list:[],
  hasError: false,
  lead: [] as Array<Generics>,
};