import { Generics } from '@/core/types';


export type State = {
  genders: Array<Generics>;
  prefex: Array<Generics>;
  marital_status: Array<Generics>;
  relation_types: Array<Generics>;
  product_types: Array<Generics>;
  period_types: Array<Generics>;
  trip_types: Array<Generics>;
  max_traveller_count: number | null;
}

export const state: State = {
  genders: [] as Array<Generics>,
  prefex: [] as Array<Generics>,
  marital_status: [] as Array<Generics>,
  relation_types: [] as Array<Generics>,
  product_types: [] as Array<Generics>,
  period_types: [] as Array<Generics>,
  trip_types: [] as Array<Generics>,
  max_traveller_count: 8,
};

