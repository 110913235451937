import { store } from "@/store";
import { App } from "vue";
import { CheckoutsActionTypes } from "@/core/enum/Checkouts/Actions";
import { Checkout as check } from '@/core/types/Checkouts';

class Checkouts{

    public static vueInstance: App;

    public static init(app: App<Element>) {
        Checkouts.vueInstance = app;
    }

    public static async postCheckout(payload: check) {
        return await store
        .dispatch(CheckoutsActionTypes.POST_CHECKOUT, payload)
    }

    public static fetchPayfort(payload: number) {
        store
        .dispatch(CheckoutsActionTypes.GET_PAYFORT_FORM, payload)
    }

}

export default Checkouts;