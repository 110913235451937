/* eslint-disable */
import { MutationTree } from 'vuex';

import { Travel, Result } from '@/core/types';

import { State } from './state';
import { ReviewMutationTypes } from '@/core/enum/Review/Mutations';

export type Mutations<S = State> = {
  [ReviewMutationTypes.SET_TRAVELLER_MEMBERS](state: S, payload: Travel): void;
  [ReviewMutationTypes.SET_TRAVELLER_RESULT](state: S, payload: Result): void;
  [ReviewMutationTypes.SET_WHATSAPP_TEMPLATE](state: S, payload: any): void;
  [ReviewMutationTypes.SET_TRAVELLER_LOADING](state: S, payload: boolean): void;
}

export const mutations: MutationTree<State> & Mutations = {
  [ReviewMutationTypes.SET_TRAVELLER_MEMBERS](state: State, customer: any) {
    state.review_details = customer;
  },
  [ReviewMutationTypes.SET_TRAVELLER_RESULT](state: State, result: Result) {
    state.result = result;
  },
  [ReviewMutationTypes.SET_WHATSAPP_TEMPLATE](state: State, whatapp_template: any) {
    state.whatapp_template = whatapp_template;
  },
  [ReviewMutationTypes.SET_TRAVELLER_LOADING](state: State, loading: boolean) {
    state.loading = loading;
  },
};
