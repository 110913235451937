import { MutationTree } from 'vuex';

import { Generics } from '@/core/types';

import { State } from './state';
import { QuoteMutationTypes } from '@/core/enum/Quotes/Mutations';

export type Mutations<S = State> = {
  [QuoteMutationTypes.SET_QUOTES](state: S, payload: Array<Generics>): void;
  [QuoteMutationTypes.SET_INFORMATIONS](state: S, payload: Array<Generics>): void;
  [QuoteMutationTypes.SET_WHATSAPP_TEMPLATE](state: S, payload: any): void;
  [QuoteMutationTypes.SET_INTERESTED_POLICY](state: S, payload: any): void;
  [QuoteMutationTypes.SET_HAS_ERROR](state: S, payload: any): void;
  [QuoteMutationTypes.SET_VAT](state: S, payload: number): void;
  [QuoteMutationTypes.SET_LEAD_INFORMATIONS](state: S, payload: Array<Generics>): void;
}

export const mutations: MutationTree<State> & Mutations = {
  [QuoteMutationTypes.SET_QUOTES](state: State, quotes: Array<Generics>) {
    state.quotes = quotes;
  },
  [QuoteMutationTypes.SET_INFORMATIONS](state: State, informations: Array<Generics>) {
    state.informations = informations;
  },
  [QuoteMutationTypes.SET_WHATSAPP_TEMPLATE](state: State, whatapp_template: any) {
    state.whatapp_template = whatapp_template;
  },
  [QuoteMutationTypes.SET_INTERESTED_POLICY](state: State, policy: any) {
    state.interested_policy = policy;
  },
  [QuoteMutationTypes.SET_HAS_ERROR](state: State, error: any) {
    state.hasError = error;
  },
  [QuoteMutationTypes.SET_VAT](state: State, vat: number) {
    state.vat = vat;
  },
  [QuoteMutationTypes.SET_LEAD_INFORMATIONS](state: State, lead: Array<Generics>) {
    state.lead = lead;
  },
};
