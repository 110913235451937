import { store } from "@/store";
import { App } from "vue";

export const dropDown = (app: App<Element>) => {
    app.config.globalProperties.dropDown = {
        getNationality(e: number) {
            const countries = store.state.countries.countries;
            const nationality = countries.find(x => x.value === e);
            return nationality?.label;
        },
        
        getPrefex(e: number) {
            const prefexs = store.state.insurances.prefex;
            const type = prefexs.find(x => x.value === e);
            return type?.label;
        },

        getRelationship(e: number) {
            const relationshipList = store.state.insurances.relation_types;
            const relationship = relationshipList.find(x => x.value === e);
            return relationship?.label;
        },

        getGender(e: string) {
            const genderList = store.state.insurances.genders;
            const gender = genderList.find(x => x.value === e);
            return gender?.label;
        },

        getMaritalStatus(e: string) {
            const maritalStatusList = store.state.insurances.marital_status;
            const maritalStatus = maritalStatusList.find(x => x.value === e);
            return maritalStatus?.label;
        }
    }
}