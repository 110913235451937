/* eslint-disable */
import { store } from "@/store";
import { App } from "vue";
import { PersonalActionTypes } from "@/core/enum/Personals/Actions";
import { PersonalMutationTypes } from "@/core/enum/Personals/Mutations";
import { Result, Generics } from '@/core/types';

class personalDetails{

    public static vueInstance: App;

    public static init(app: App<Element>) {
        personalDetails.vueInstance = app;
    }

    public static async post(payload) {
        return await store
        .dispatch(PersonalActionTypes.POST_PERSONAL_DETAILS, payload);
    }
    
    public static async getLatestLeads(payload) {
        return await store.dispatch(PersonalActionTypes.GET_LATEST_LEADS, payload);
    }

    public static async getDetails(payload) {
        return await store.dispatch(PersonalActionTypes.GET_PERSONAL_DETAILS, payload);
    }

    public static setCustomer(payload: any) {
        store.commit(PersonalMutationTypes.SET_CUSTOMER, payload);
    }

    public static setResult(payload: Result) {
        store.commit(PersonalMutationTypes.SET_CUSTOMER_RESULT, payload);
    }

    public static setRelationships(value) {
        if(value) {
            store.state.relationships.families.push({
                value: 5, label: "Principal"
            });
        } 
    }

    public static setMembers(payload: Array<Generics>) {
        store.commit(PersonalMutationTypes.SET_CUSTOMER_MEMBERS, payload);
    }

    public static setLoading(payload: boolean) {
        store.commit(PersonalMutationTypes.SET_CUSTOMER_LOADING, payload);
    }

    public static setCustomerLeads(payload: boolean) {
        store.dispatch(PersonalActionTypes.SET_CUSTOMER_LEADS, payload);
    }

    public static setExistClick(payload: boolean) {
        store.commit(PersonalMutationTypes.SET_EXIST_CLICK, payload);
    }
}

export default personalDetails;