import { store } from "@/store";
import { App } from "vue";
import { CouponActionTypes } from "@/core/enum/Coupons/Actions";
import { CouponMutationTypes } from "@/core/enum/Coupons/Mutations";


class Coupons {

    public static vueInstance: App;

    public static init(app: App<Element>) {
        Coupons.vueInstance = app;
    }

    public static async postCoupon(payload: object) {
        return await store.dispatch(CouponActionTypes.POST_COUPON, payload)
    }

    public static removeCoupon() {
        window.localStorage.removeItem('coupon')
        store.commit(CouponMutationTypes.SET_COUPON_SUCCESS, null);
    }

    // public static setCoupon(payload: Object) {
    //     store.commit(CouponMutationTypes.SET_COUPON_SUCCESS, payload);
    // }

}

export default Coupons;