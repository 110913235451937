export enum ReviewMutationTypes {
    SET_TRAVELLER = 'SET_TRAVELLER',
    SET_TRAVELLER_MEMBERS = 'SET_TRAVELLER_MEMBERS',
    SET_WHATSAPP_TEMPLATE = 'SET_WHATSAPP_TEMPLATE',
    SET_TRAVELLER_RESULT = 'SET_TRAVELLER_RESULT',
    SET_TRAVELLER_LOADING = 'SET_TRAVELLER_LOADING',
    SET_TRAVELLER_EXISTING_LEADS = 'SET_TRAVELLER_EXISTING_LEADS',
    SET_TRAVELLER_EXISTING_SELECTED_LEADS = 'SET_TRAVELLER_EXISTING_SELECTED_LEADS',
    SET_INSURED_TYPE_DISABLED = 'SET_INSURED_TYPE_DISABLED',
    SET_EXIST_CLICK = 'SET_EXIST_CLICK',
    SET_QUOTES = 'SET_QUOTES',
    SET_INFORMATIONS = 'SET_INFORMATIONS',
    SET_INTERESTED_POLICY = 'SET_INTERESTED_POLICY',
    SET_SELECTED_PREMIUM = 'SET_SELECTED_PREMIUM',
    SET_VAT = 'SET_VAT',
  }
  