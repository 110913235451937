import moment from "moment/moment";
import { reactive, ref } from "vue";

const loading = ref(false)
const svgLoading = `
        <path class="path" d="
          M 30 15
          L 28 17
          M 25.61 25.61
          A 15 15, 0, 0, 1, 15 30
          A 15 15, 0, 1, 1, 27.99 7.5
          L 15 15
        " style="stroke-width: 4px; fill: rgba(0, 0, 0, 0)"/>
      `

const focus = reactive({
  personal: '',
  member: '',
  review: '',
});

const emirates = reactive({
  abhu_dhabi: 1, 
  ajman: 2, 
  dubai: 3, 
  fujairah: 4, 
  ras_al_khaimah: 5, 
  sharjah: 6, 
  umm_al_quwain: 7, 
})

const insurance_for = reactive({
  myself: 1,
  domestic_worker: 2,
  family: 3,
  employee: 4,
  parents: 5
})

const insuredFamilyValue = 3

const insuredParentValue = 5

const insuredDomesticWorkerValue = 2

const screen = reactive({
  width: window.screen.width
})

const dates = reactive({
  min: moment().subtract(18,'y').format('YYYY-MM-DD'),
  min_age: moment().subtract(100, 'y').format('YYYY-MM-DD'),
  dob: moment().subtract(0,'d').format('YYYY-MM-DD'),
  today: moment().format('YYYY-MM-DD'),
  expected_date: moment().add(0, 'd').format('YYYY-MM-DD')
})

const trip_dates_filter = (selectedDate) => {
  return moment(selectedDate).add(12, 'M').subtract(1, 'd').format('YYYY-MM-DD')
}

// function
const setDateMdY = (val) => {
  const arr = new Date(val).toString().split(" ");
  return arr[1] + " " + arr[2] + ", " + arr[3];
};


const setDateOnly = (e) => {
  const MF = moment(e, 'DD/MM/YYYY').isValid()
  if(!MF) return moment(e).format("DD/MM/YYYY");
  else return e
}

const getTextWidth = (text, font) => {
  const canvas = document.createElement("canvas");
  if(canvas) {
      const context = canvas.getContext("2d");

      context.font = font || getComputedStyle(document.body).font;

      return context.measureText(text).width;
  }
}

export { focus, emirates, insurance_for, dates, screen, insuredFamilyValue , insuredParentValue, insuredDomesticWorkerValue, loading, svgLoading, getTextWidth, trip_dates_filter, setDateMdY, setDateOnly };