/* eslint-disable */
import { Result, Generics, RecentSearches, Travel } from '@/core/types';

export type State = {
  review_details: Travel;
  result: Result;
  travellers_info: Array<Generics>;
  recent_searches:RecentSearches;
  whatapp_template:string | null;
  loading: boolean;
}

export const state: State = {  
  review_details: {
    id: null,
    lang: window.localStorage.getItem('language'),
    name: null,
    is_self: false,
    inbound_outbound: true,
    email: null,
    phone_number: null,
    phone_country_code: "+961",
    complete_phone_number: null,
    agent_id: window.localStorage.getItem('agent_id') ?? null,
    destination_id: [],
    no_of_traveller: 1,
    source_id: null,
    trip_type: null,
    destination_country:null,
    product_type: 1,
    start_date: null,
    end_date: null,
    no_of_days: 0,
    is_travel: 1,
    source: null,
    utm_source: null,
    utm_medium: null,
    utm_campaign: null,
    utm_content: null,
    dob: null,
    invoice: null,
    deleted_members:[],
    travellers_info: [],
    session_id : null
  } as Travel,
  result: {} as Result,
  recent_searches: {} as RecentSearches,
  travellers_info: [] as Array<Generics>,
  whatapp_template: '',
  loading: false
};

