import { App } from 'vue';
import { initComponent } from '../components';
import { dates } from '@/core/functions/dateValues';
import { personalDetails } from '@/core/functions/personalDetails';
import { reviewDetails } from '@/core/functions/reviewDetails';
import { dropDown } from '@/core/functions/dropDownValues';
import { rule } from '@/core/functions/rulesValues';
import { header } from '@/core/functions/headerFunction';
import { currency } from '@/core/functions/currency';

import Language from '@/core/services/Language';
import Countries from '@/core/services/Countries';
import Checkouts from '@/core/services/Checkouts';
import Insurances from '@/core/services/Insurances';
import Relationships from '@/core/services/Relationships';
import PersonalService from '@/core/services/PersonalDetails';
import ReviewService from '@/core/services/ReviewDetails';
import Quotes from '@/core/services/Quotes';
import ApiService from '@/core/services/ApiService';

export function initGlobal (app: App<Element>) {
    initComponent(app);
    
    dates(app);
    currency(app);
    reviewDetails(app);
    personalDetails(app);
    dropDown(app);
    rule(app);
    header(app);
    
    Language.init(app);
    Countries.init(app);
    Insurances.init(app);
    Relationships.init(app);
    PersonalService.init(app);
    ReviewService.init(app);
    Quotes.init(app);
    Checkouts.init(app);
    ApiService.init(app);
}