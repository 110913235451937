import { App } from "vue";

const currencyFormater = (val: number, decimalPlace = 0) => {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: decimalPlace
    });

    return formatter.format(val)
}

export const currency = (app: App<Element>) => {
    app.config.globalProperties.currency = {
        format(value: number,spacing = 0) {
            // return value;
            return currencyFormater(value, 0);
        },
    }
}