import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "traveller_color_seq" }
const _hoisted_3 = { class: "vechile-details-list" }
const _hoisted_4 = ["element-loading-spinner"]
const _hoisted_5 = { class: "row form-block travel-date" }
const _hoisted_6 = { class: "col-md-4" }
const _hoisted_7 = { class: "col-md-4" }
const _hoisted_8 = { class: "col-md-4" }
const _hoisted_9 = { class: "row form-block travel-date" }
const _hoisted_10 = { class: "col-md-4" }
const _hoisted_11 = { class: "col-md-4" }
const _hoisted_12 = {
  key: 0,
  class: "col-md-4"
}
const _hoisted_13 = {
  key: 1,
  class: "col-md-4"
}
const _hoisted_14 = { class: "col-md-4" }
const _hoisted_15 = { class: "col-md-4" }
const _hoisted_16 = { class: "col-md-4" }
const _hoisted_17 = { class: "d-flex align-items-center justify-content-center btn-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_h_input = _resolveComponent("h-input")!
  const _component_h_date_v2 = _resolveComponent("h-date-v2")!
  const _component_h_gender = _resolveComponent("h-gender")!
  const _component_h_nationality = _resolveComponent("h-nationality")!
  const _component_h_back = _resolveComponent("h-back")!
  const _component_h_proceed = _resolveComponent("h-proceed")!
  const _component_el_form = _resolveComponent("el-form")!
  const _directive_loading = _resolveDirective("loading")!

  return (_ctx.item.is_editable)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t("traveller_seq", { count: _ctx.counting + 1 })), 1),
        _createVNode(_component_el_form, {
          ref: "formReviewMember",
          model: _ctx.item,
          "status-icon": "",
          rules: _ctx.rules,
          class: "demo-ruleForm"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _withDirectives((_openBlock(), _createElementBlock("div", {
                "element-loading-spinner": _ctx.svgLoading,
                "element-loading-svg-view-box": "-10, -10, 50, 50",
                "element-loading-background": "rgba(122, 122, 122, 0.3)"
              }, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(_component_h_input, {
                      isRequired: "",
                      label: _ctx.$t('type'),
                      "is-type": "select",
                      "form-prop": "type",
                      modelValue: _ctx.item.type,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.item.type) = $event)),
                      options: _ctx.insurance.prefex,
                      ref: "type"
                    }, null, 8, ["label", "modelValue", "options"])
                  ]),
                  _createElementVNode("div", _hoisted_7, [
                    _createVNode(_component_h_input, {
                      isRequired: true,
                      label: _ctx.$t('first_name'),
                      isType: "text",
                      "form-prop": "first_name",
                      modelValue: _ctx.item.first_name,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.item.first_name) = $event)),
                      "max-length": "62",
                      onkeydown: _ctx.allowAlphabetsOnly,
                      "tooltip-message": _ctx.$t('passport_tooltip'),
                      "is-tooltip": "",
                      ref: "first_name",
                      placeHolder: _ctx.$t('first_name')
                    }, null, 8, ["label", "modelValue", "onkeydown", "tooltip-message", "placeHolder"])
                  ]),
                  _createElementVNode("div", _hoisted_8, [
                    _createVNode(_component_h_input, {
                      label: _ctx.$t('middle_name'),
                      isType: "text",
                      "form-prop": "middle_name",
                      modelValue: _ctx.item.middle_name,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.item.middle_name) = $event)),
                      "max-length": "62",
                      onkeydown: _ctx.allowAlphabetsOnly,
                      "tooltip-message": _ctx.$t('middle_name_tooltip'),
                      ref: "middle_name",
                      placeHolder: _ctx.$t('middle_name')
                    }, null, 8, ["label", "modelValue", "onkeydown", "tooltip-message", "placeHolder"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    _createVNode(_component_h_input, {
                      isRequired: true,
                      label: _ctx.$t('last_name'),
                      isType: "text",
                      "form-prop": "last_name",
                      modelValue: _ctx.item.last_name,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.item.last_name) = $event)),
                      "max-length": "62",
                      onkeydown: _ctx.allowAlphabetsOnly,
                      "tooltip-message": _ctx.$t('last_name_tooltip'),
                      ref: "last_name",
                      placeHolder: _ctx.$t('last_name')
                    }, null, 8, ["label", "modelValue", "onkeydown", "tooltip-message", "placeHolder"])
                  ]),
                  _createElementVNode("div", _hoisted_11, [
                    _createVNode(_component_h_date_v2, {
                      isRequired: true,
                      dayProp: `dob`,
                      "model-value": _ctx.item.dob,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.item.dob) = $event)),
                      "form-prop": "dob",
                      label: _ctx.$t('member_dob'),
                      ref: `dob`,
                      defaultValue: _ctx.defaultDob,
                      disabled: true,
                      placeHolder: _ctx.$t('member_dob'),
                      max: _ctx.dDate.today,
                      min: _ctx.dDate.min_age
                    }, null, 8, ["model-value", "label", "defaultValue", "placeHolder", "max", "min"])
                  ]),
                  (_ctx.showForIsa)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                        _createVNode(_component_h_gender, {
                          isRequired: true,
                          label: _ctx.$t('gender'),
                          formProp: "gender_id",
                          modelValue: _ctx.item.gender_id,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.item.gender_id) = $event)),
                          dropdown: _ctx.insurance.genders,
                          ref: "gender_id"
                        }, null, 8, ["label", "modelValue", "dropdown"])
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.showForOrient)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                        _createVNode(_component_h_input, {
                          isRequired: true,
                          label: _ctx.$t('passport'),
                          isType: "text",
                          "form-prop": "passport",
                          modelValue: _ctx.item.passport,
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.item.passport) = $event)),
                          "tooltip-message": _ctx.$t('passport_tooltip'),
                          "max-length": "20",
                          ref: "passport",
                          placeHolder: _ctx.$t('passport'),
                          onkeydown: _ctx.allowAlphaNumeric,
                          onchange: _ctx.allowAlphaNumeric
                        }, null, 8, ["label", "modelValue", "tooltip-message", "placeHolder", "onkeydown", "onchange"])
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.showForIsa || (!_ctx.is_api_plan))
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                        _createElementVNode("div", _hoisted_14, [
                          _createVNode(_component_h_nationality, {
                            isRequired: true,
                            label: _ctx.$t('country_of_residence'),
                            modelValue: _ctx.item.country_id,
                            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.item.country_id) = $event)),
                            formProp: "country_id",
                            ref: "country_id"
                          }, null, 8, ["label", "modelValue"])
                        ]),
                        _createElementVNode("div", _hoisted_15, [
                          _createVNode(_component_h_nationality, {
                            isRequired: true,
                            label: _ctx.$t('nationality'),
                            modelValue: _ctx.item.nationality_id,
                            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.item.nationality_id) = $event)),
                            formProp: "nationality_id",
                            ref: "nationality_id"
                          }, null, 8, ["label", "modelValue"])
                        ])
                      ], 64))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_16, [
                    _createVNode(_component_h_input, {
                      isRequired: true,
                      label: _ctx.$t('passport'),
                      isType: "text",
                      "form-prop": "passport",
                      modelValue: _ctx.item.passport,
                      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.item.passport) = $event)),
                      "tooltip-message": _ctx.$t('passport_tooltip'),
                      "max-length": "20",
                      ref: "passport",
                      placeHolder: _ctx.$t('passport'),
                      onkeydown: _ctx.allowAlphaNumeric,
                      onchange: _ctx.allowAlphaNumeric
                    }, null, 8, ["label", "modelValue", "tooltip-message", "placeHolder", "onkeydown", "onchange"])
                  ])
                ])
              ], 8, _hoisted_4)), [
                [_directive_loading, _ctx.loading]
              ]),
              _createElementVNode("div", _hoisted_17, [
                _createVNode(_component_h_back, {
                  onHandleClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.previousTraveller(_ctx.counting))),
                  disabled: _ctx.counting == 0
                }, null, 8, ["disabled"]),
                _createVNode(_component_h_proceed, {
                  onHandleClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.reviewDetails.click(_ctx.formReviewMember)))
                })
              ])
            ])
          ]),
          _: 1
        }, 8, ["model", "rules"])
      ]))
    : _createCommentVNode("", true)
}