
/* eslint-disable */
import { computed, defineComponent, toRef, watchEffect, getCurrentInstance } from "vue";
import { months, month_mobiles } from "@/core/utils";
import moment from "moment";
import { focus as dFocus, screen } from "@/store/stateless"

export default defineComponent({
  props: {
    label: {
      type: String,
      required: true,
      default: "Date of Birth",
    },
    formProp: {
      type: String,
      required: true,
      default: "dob",
    },
    modelValue: {
      type: String,
      required: true,
      default: "",
    },
    dayValue: {
      type: String,
      required: true,
      default: "",
    },
    dayProp: {
      type: String,
      required: true,
      default: "dob_day",
    },
    dayPH: {
      type: String,
      required: true,
      default: "Day",
    },
    monthValue: {
      type: String,
      required: true,
      default: "",
    },
    monthProp: {
      type: String,
      required: true,
      default: "dob_month",
    },
    monthPH: {
      type: String,
      required: true,
      default: "Month",
    },
    yearValue: {
      type: String,
      required: true,
      default: "",
    },
    yearProp: {
      type: String,
      required: true,
      default: "dob_year",
    },
    yearPH: {
      type: String,
      required: true,
      default: "Year",
    },
    isTooltip: {
      type: Boolean,
      required: false,
      default: false,
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    tooltipMessage: {
      type: String,
      required: false,
      default: "",
    },
    isDOB: {
      type: Boolean,
      required: false,
      default: true
    },
    ageValue: {
      type: Number,
      required: false,
      default: 0
    },
  },

  setup(_, context) {
    /* eslint-disable */
    const language = computed(() => {
      return window.localStorage.getItem("language");
    });

    const currentInstance = getCurrentInstance();
    const birth = currentInstance?.appContext.config.globalProperties.dates;

    const myDate = toRef(_, "modelValue");
    const myAge = toRef(_, "ageValue");
    const myDay = toRef(_, "dayValue");
    const myMonth = toRef(_, "monthValue");
    const myYear = toRef(_, "yearValue");

    const selectedDay = (event) => {
      context.emit("update:dayValue", event);
    };

    const selectedMonth = (event) => {
      context.emit("update:monthValue", event);
    };

    const selectedYear = (event) => {
      context.emit("update:yearValue", event);
    };

    watchEffect(() => {
      const day = myDay.value.toString().length == 1 ? `0${myDay.value}` : myDay.value;
      const month =
        myMonth.value.toString().length == 1 ? `0${myMonth.value}` : myMonth.value;
      const dob = moment(`${myYear.value}-${month}-${day}`).format('YYYY-MM-DD');
      myDate.value = dob;
      // myAge.value = 
      if(myDate.value !== 'Invalid date') {
        myAge.value = birth.getAge(dob);
        context.emit("update:ageValue", birth.getAge(dob));
      } 
      if(day && month && myYear.value) context.emit("update:modelValue", dob);
    });

    const focus = () => {
        let toFocus:any = null;
        if(dFocus.personal !== '') {
          toFocus = currentInstance?.refs[dFocus.personal]
        }
        if(dFocus.member !== '') {
          toFocus = currentInstance?.refs[dFocus.member]
        }
        toFocus.focus()
    }


    return {
      screen,
      focus,
      selectedYear,
      selectedMonth,
      selectedDay,
      myAge,
      myDate,
      myDay,
      myMonth,
      myYear,
      months,
      month_mobiles,
      language,
    };
  },
});
