import { MutationTree } from 'vuex';

import { Insurance } from '@/core/types';

import { State } from './state';
import { InsuranceMutationTypes } from '@/core/enum/Insurances/Mutations';

export type Mutations<S = State> = {
  [InsuranceMutationTypes.SET_MASTER_CONFIG](state: S, payload: Insurance): void;
}

export const mutations: MutationTree<State> & Mutations = {
  [InsuranceMutationTypes.SET_MASTER_CONFIG](state: State, insuredTypes: Insurance) {
    state.genders = insuredTypes.genders;
    state.prefex = insuredTypes.prefex;
    state.marital_status = insuredTypes.marital_status;
    state.relation_types = insuredTypes.relation_types;
    state.product_types = insuredTypes.product_types;
    state.period_types = insuredTypes.period_types;
    state.trip_types = insuredTypes.trip_types;
    state.max_traveller_count = insuredTypes.max_traveller_count;
  },
};
