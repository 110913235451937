
/* eslint-disable */
import { computed, defineComponent, onMounted, toRef, ref, inject } from "vue";
import Countries from "@/core/services/Countries";
import { store } from "@/store";


export default defineComponent({
  props: {
    isType: {
      type: String,
      required: true,
      default: "text",
    },
    label: {
      type: String,
      required: true,
      default: "",
    },
    formProp: {
      type: String,
      required: true,
      default: "",
    },
    modelValue: {
      type: String,
      required: true,
      default: "",
    },
    countryCode: {
      type: String,
      required: false,
      default: "",
    },
    placeHolder: {
      type: String,
      required: false,
      default: "",
    },
    tooltipMessage: {
      type: String,
      required: false,
      default: "",
    },
    maxLength: {
      type: String,
      required: false,
    },
    onKey: {
      type: String,
      required: false,
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    min: {
      type: String,
      required: false,
      default: "1",
    },
    max: {
      type: String,
      required: false,
      default: "10",
    },
    isTooltip: {
      type: Boolean,
      required: false,
      default: false,
    },
    options: {
      type: Array,
      required: false,
    },
  },

  setup(props, context) {
    const isType = toRef(props, "isType");
    const isMobile = inject('isMobile')

    onMounted(async () => {
      if (isType.value === "phone") await Countries.getCountryCode();
      if (isType.value === "step") {
        const elNumber:any = document.getElementsByName('steppers')
        elNumber[0].disabled = true
      }
    });

    const countries = computed(() => {
      return store.state.countries.countryCode;
    });

    const updateValue = (event) => {
      context.emit("update:modelValue", event);
      context.emit("input", event);
    };

    const stepChange = (value: number) => {
      context.emit("update:modelValue", value);
      // context.emit("input", value);
    };

    const updateCountryValue = (event) => {
      context.emit("update:countryCode", event);
      context.emit("change", event);
    };

    const name = ref()
    const focus = () => {
      setTimeout(() => {
        name.value.focus()
      }, 500);
    }
    const colSize = (props.formProp == 'source_id' || props.formProp == 'destination_id' || props.formProp == 'no_of_traveller' || props.formProp == 'email' || props.formProp == 'phone_number') ? 'col-lg-12' : 'col-lg-12';

    return {
      name,
      focus,
      updateValue,
      updateCountryValue,
      stepChange,
      countries,
      colSize,
      isType,
    };
  },
});
