
/* eslint-disable */
import { defineComponent, ref, computed } from "vue";
import { Travel } from "@/core/types";
import { FormInstance } from "element-plus";
import { store } from "@/store";
import { focus, dates as dDate } from "@/store/stateless"
import moment from "moment";

export default defineComponent({
  name: 'membersComponent',
  props: {
    members: {
      type: Array,
      required: true,
      default: []
    },
    customer: {
      type: Object,
      required: true,
      default: {} as Travel,
    },
  },
  
  setup(_) {    
    const formMemberDetails = ref<FormInstance>();
    
    const editedTraveller = computed(() => {
      return store.state.personals.customer.travellers_info
    })

    const language = computed(() => {
      return window.localStorage.getItem('language');
    })

    
    const defaultDob = computed(() => {
      return moment().subtract(30,'y').format('YYYY-MM-DD')
    })
    
    const v_dob = (rule, values, callback) => {
      const obj = rule.field.split('.')[1] 
      console.log(editedTraveller.value[obj].dob);
      if(editedTraveller.value[obj].dob === 'Invalid date' || !editedTraveller.value[obj].dob) {
        callback(new Error( language.value === 'ar' ? 'الرجاء تحديد تاريخ ميلاد صحيح' : 'Please select correct date of birth'));
      } else if(moment().diff(editedTraveller.value[obj].dob, 'days') < 0) {
        callback(new Error( language.value === 'ar' ? 'الرجاء تحديد تاريخ ميلاد صحيح' : 'Please select correct date of birth'));
      } else {
        callback();
      }
    }

    return {
      focus,
      dDate,
      v_dob,
      defaultDob,
      formMemberDetails
      
    }
  }
});
